// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/assets/images/icons/v2/24/24-close.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes keyframes-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes keyframes-slide-in {
  0% {
    opacity: 0;
    top: 47%;
  }
  100% {
    opacity: 1;
    top: 48%;
  }
}
@keyframes keyframes-grow-in {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes keyframes-shake {
  0% {
    transform: translate(-50%, -50%);
  }
  25% {
    transform: translate(-51%, -50%);
  }
  50% {
    transform: translate(-49%, -50%);
  }
  75% {
    transform: translate(-51%, -50%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}
modal-dialog, .modal-dialog {
  display: flex;
  flex-direction: column;
  position: absolute !important;
  left: 50%;
  top: 48%;
  transform: translate(-50%, -50%);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  animation: keyframes-slide-in 300ms cubic-bezier(0, 0, 0.38, 0.9) 0ms;
  position: absolute;
  width: 640px;
  height: calc(100vh - 120px);
  z-index: 10;
  box-sizing: border-box;
  background-color: #FFFFFF;
  border-radius: 6px;
}
modal-dialog header, .modal-dialog header {
  display: flex;
  flex-direction: row;
  padding: 24px 24px 24px 24px;
  justify-content: flex-end;
}
modal-dialog header div.icon, .modal-dialog header div.icon {
  cursor: pointer !important;
  user-select: none;
  width: 24px;
  height: 24px;
  background-color: #181818;
}
modal-dialog header div.icon-close, .modal-dialog header div.icon-close {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
modal-dialog nav, .modal-dialog nav {
  padding: 0 24px 0 24px;
}
modal-dialog nav h1, .modal-dialog nav h1 {
  font-family: "Incinet Semi Bold", sans-serif;
  font-size: 21px;
  font-style: normal;
  line-height: normal;
  color: #181818;
  margin-bottom: 16px;
}
modal-dialog nav p, .modal-dialog nav p {
  font-family: "Incinet Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  color: #181818;
}
modal-dialog section, .modal-dialog section {
  flex-grow: 1;
  overflow: auto;
  padding: 0 16px 24px 24px;
  scrollbar-gutter: stable;
}
modal-dialog section div.item, .modal-dialog section div.item {
  display: flex;
  flex-direction: row;
  cursor: pointer !important;
  user-select: none;
  min-height: 56px;
  background-color: #F2F2F2;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 6px;
  border-radius: 6px;
}
modal-dialog section div.item div.label, .modal-dialog section div.item div.label {
  font-family: "Incinet Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  color: #181818;
}
modal-dialog section div.item div.price, .modal-dialog section div.item div.price {
  font-family: "Incinet Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  color: #181818;
}
modal-dialog footer, .modal-dialog footer {
  padding: 24px 24px 24px 24px;
}`, "",{"version":3,"sources":["webpack://./src/runko/styles/_animations.scss","webpack://./src/calculator/components/modal-dialog/modal-dialog.scss","webpack://./src/runko/styles/_mixins.scss","webpack://./src/runko/styles/_colors.scss","webpack://./src/runko/styles/_styles.scss"],"names":[],"mappings":"AAAA;EACE;IAAK,UAAA;ECEL;EDDA;IAAO,UAAA;ECIP;AACF;ADFA;EACE;IACE,UAAA;IACA,QAAA;ECIF;EDFA;IACE,UAAA;IACA,QAAA;ECIF;AACF;ADDA;EACE;IACE,UAAA;IACA,qBAAA;ECGF;EDDA;IACE,UAAA;IACA,mBAAA;ECGF;AACF;ADAA;EACE;IACE,gCAAA;ECEF;EDAA;IACE,gCAAA;ECEF;EDAA;IACE,gCAAA;ECEF;EDAA;IACE,gCAAA;ECEF;EDAA;IACE,gCAAA;ECEF;AACF;AAvCA;ECmCE,aAAA;EACA,sBAAA;EA6BA,6BAAA;EACA,SAAA;EACA,QAAA;EACA,gCAAA;EAmBA,0CAAA;EAJA,qEAAA;ED9EA,kBAAA;EACA,YAAA;EACA,2BAAA;EACA,WAAA;EACA,sBAAA;EACA,yBEbS;EFcT,kBAAA;AA6CF;AA3CE;ECiBA,aAAA;EACA,mBAAA;EDhBE,4BAAA;EACA,yBAAA;AA8CJ;AA5CI;ECmCF,0BAAA;EACA,iBAAA;EDlCI,WAAA;EACA,YAAA;EACA,yBExBI;AFuEV;AA7CM;EACE,mDAAA;AA+CR;AA1CE;EACE,sBAAA;AA4CJ;AA1CI;ECjCF,4CAAA;EE+DA,eAAA;EACA,kBAAA;EACA,mBAAA;EH9BI,cErCI;EFsCJ,mBAAA;AA+CN;AA5CI;EC3CF,0CAAA;EEWA,eAAA;EACA,kBAAA;EACA,mBAAA;EHgCI,cE3CI;AF4FV;AA5CE;ECFA,YAAA;EDIE,cAAA;EACA,yBAAA;EACA,wBAAA;AA8CJ;AA5CI;ECtBF,aAAA;EACA,mBAAA;EAsBA,0BAAA;EACA,iBAAA;EDCI,gBAAA;EACA,yBExDI;EFyDJ,8BAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,kBAAA;AAgDN;AA9CM;ECnEJ,0CAAA;EEWA,eAAA;EACA,kBAAA;EACA,mBAAA;EHwDM,cEnEE;AFsHV;AAhDM;ECxEJ,0CAAA;EEWA,eAAA;EACA,kBAAA;EACA,mBAAA;EH6DM,cExEE;AF6HV;AAhDE;EACE,4BAAA;AAkDJ","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
