// General stylesheets are imported here.
// Beware of all SASS code that generates CSS output only ONCE nad here.
import "runko/styles/index.scss";

import "./calculator-app.scss";
import template from "./calculator-app.hbs";

// Workaround: testing pricelist from local json
import { component, query } from "../../../hiyo/decorators.js";
import { Component } from "../../../hiyo/component.js";
import { TableSummary } from "../table-summary/table-summary.js";
import { TextInput } from "../text-input/text-input.js";
import { Log } from "../../../hiyo/log.js";
import { Templates } from "../../../hiyo/templates.js";
import { TableRow } from "../table-row/table-row.js";
import { Calculation } from "./types.js";
import { ModulePicker } from "../module-picker/module-picker.js";
import { TableRowOptions } from "../table-row/types.js";
import { DomHelper } from "../../../hiyo/dom-helper.js";
import { OverlayPanel } from "../overlay-panel/overlay-panel.js";
import { ConnectorPicker } from "../connector-picker/connector-picker.js";
import { ServicePicker } from "../service-picker/service-picker.js";
import { ApiClient } from "../../../incinet/clients/api-client/api-client.js";

@component(template, true)
export class CalculatorApp extends Component {

    // Properties
    public api: ApiClient;
    public pricelist: any;
    public submitted: boolean;

    // Components
    @query("text-input[name=project]") public project: TextInput;
    @query("text-input[name=customer]") public customer: TextInput;
    @query("text-input[name=email]") public email: TextInput;
    @query("text-input[name=phone]") public phone: TextInput;
    @query("text-input[name=note]") public note: TextInput;
    @query("div.table-services table-row") public maintenance: TableRow;
    @query("table-summary") public summary: TableSummary;

    public async onCreate() {
        Log.i("Welcome to Invipo calculator. The world of fantasy🤡");

        // Register Handlebars templates
        Templates.registerHelpers();

        // Create new API client
        this.api = new ApiClient({
            host: "https://www.incinet.cz"
        });

        // Put application instance (this) as a global window.app reference for better debugging
        (<any>window).app = this;
    }

    public onRender(): void {
        // Exit if already submitted
        if (this.submitted) {
            return;
        }

        // Add one year of maintenance
        let maintenance = new TableRow(null, {
            article: {
                code: "standard-maintenance",
                name: "Standard maintenance",
                count: 1,
                price: 0,
                unit: this.pricelist.maintenance.unit
            },
            adjustable: true,
            readonly: true
        });

        // Update on change
        maintenance.onChange = () => {
            this.update();
        }

        // Add to DOM
        this.querySelector("div.table-services").appendChild(maintenance);
    }

    public getCalculation(): Calculation {
        let result: Calculation = {
            modules: [],
            connectors: [],
            maintenance: null,
            services: [],
            total: {
                licenses: 0,
                services: 0,
                price: 0
            }
        };

        // Collect modules
        this.querySelectorAll<TableRow>("div.table-modules table-row").forEach((r) => {
            // Add to result
            result.modules.push(
               {
                   article: r.options.article,
                   datasources: r.options.datasources
               }
           );

           // Add to total
           result.total.licenses += r.options.total;
           result.total.price += r.options.total;
        });

        // Collect connectors
        this.querySelectorAll<TableRow>("div.table-connectors table-row").forEach((r) => {
            // Add to result
            result.connectors.push(r.options.article);

            // Add to total
            result.total.licenses += r.options.total;
            result.total.price += r.options.total;
        });

        // Collect services
        this.querySelectorAll<TableRow>("div.table-services table-row").forEach((r) => {
            // Add to result
            result.services.push(r.options.article);

            // Add to total
            result.total.services += r.options.total;
            result.total.price += r.options.total;
        });

        // Calculate summary
        return result;
    }

    public selectModule(): void {
        // Module dialog
        let picker = new ModulePicker(this.context, {
            calculation: this.getCalculation(),
            pricelist: this.pricelist
        });

        // Add new module row
        picker.onSelect = (module: any) => {
            // Create row options from selected module
            let options: TableRowOptions = {
                size: "Large",
                article: {
                    name: module.name,
                    code: module.code,
                    price: module.price,
                    count: 1
                },
                datasources: []
            }

            // Does module have datasources?
            if (module.datasources?.length) {
                for (let datasource of module.datasources) {
                    options.datasources.push({
                        name: datasource.unit,
                        price: datasource.price,
                        count: 0
                    });
                }
            }

            // Add module row
            let row = new TableRow(this.context, options);

            // Update on change
            row.onChange = () => {
                this.update();
            }

            // Add row to DOM
            this.querySelector("div.table-modules").appendChild(row);

            // Update
            this.update();
        }

        // Add picker to DOM
        DomHelper.appendBodyChild(new OverlayPanel(), picker);
    }

    public selectConnector(): void {
        // Module dialog
        let picker = new ConnectorPicker(this.context, {
            calculation: this.getCalculation(),
            pricelist: this.pricelist
        });

        // Add new module row
        picker.onSelect = (connector: any) => {
            // Create row options from selected module
            let options: TableRowOptions = {
                article: {
                    name: connector.name,
                    code: connector.code,
                    price: connector.price,
                    count: 1
                }
            }

            // Add module row
            let row = new TableRow(this.context, options);

            // Update on change
            row.onChange = () => {
                this.update();
            }

            // Add row to DOM
            this.querySelector("div.table-connectors").appendChild(row);

            // Update
            this.update();
        }

        // Add picker to DOM
        DomHelper.appendBodyChild(new OverlayPanel(), picker);
    }

    public selectService(): void {
        // Service dialog
        let picker = new ServicePicker(this.context, {
            calculation: this.getCalculation(),
            pricelist: this.pricelist
        });

        // Add new module row
        picker.onSelect = (service: any) => {
            // Create row options from selected module
            let options: TableRowOptions = {
                article: {
                    name: service.name,
                    code: service.code,
                    price: service.price,
                    unit: service.unit,
                    count: 1
                },
                adjustable: true
            }

            // Add module row
            let row = new TableRow(this.context, options);

            // Update on change
            row.onChange = () => {
                this.update();
            }

            // Add row to DOM
            this.querySelector("div.table-services").appendChild(row);

            // Update
            this.update();
        }

        // Add picker to DOM
        DomHelper.appendBodyChild(new OverlayPanel(), picker);
    }

    public update(): void {
        let modules = this.querySelector("div.table-modules");
        let connectors = this.querySelector("div.table-connectors");
        let calculation = this.getCalculation();

        // Calculate maintenance price and redraw
        this.maintenance.setArticlePrice(Math.floor(calculation.total.licenses * (this.pricelist.maintenance.percent / 100)));

        // Check the DOM and update node appearance if necessary
        this.querySelector<HTMLElement>("p.description-modules").style.display = (modules.querySelectorAll("table-row").length > 0) ? "none" : "block";
        this.querySelector<HTMLElement>("p.description-connectors").style.display = (connectors.querySelectorAll("table-row").length > 0) ? "none" : "block";

        // We need to recalculate totals because maintenance row has changed
        calculation = this.getCalculation();

        // Recalculate summary
        this.summary.setTotal(calculation.total);
    }

    async submit(): Promise<void> {
        // Validate user inputs
        let valid =
            this.project.validate() &&
            this.customer.validate() &&
            this.email.validate() &&
            this.phone.validate();

        // Scroll to inputs if not valid
        if (!valid) {
            this.scrollTo({
                top: 0,
                behavior: "smooth"
            });

            // We end here
            return;
        }

        // Get calculation data
        let calculation = this.getCalculation();

        // Assign form data to calculation
        calculation = {
            ...calculation,
            project: this.project.options.value,
            customer: this.customer.options.value,
            email: this.email.options.value,
            phone: this.phone.options.value,
            note: this.note.options.value
        }

        //try {
            // Submit calculation
            await this.api.postCalculation(calculation)

            // Change state
            this.submitted = true;

            // Redraw
            this.render();
        //}
        //catch (e) {
//            console.info(`Error while submitting form (${e})`)
//        }
    }

    async load(): Promise<void> {
        // Load price list
        try {
            this.pricelist = (await this.api.getConfig("pricelist")).pricelist;
        }
        catch (e) {
            Log.e(`Could not load pricelist ${e}`);
        }
    }
}