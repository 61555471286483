// Create component map to register decorator
// Decorators are not executed on import statement
import { CalculatorApp } from "./components/calculator-app/calculator-app.js";
import { TextInput } from "./components/text-input/text-input.js";
import { ModalDialog } from "./components/modal-dialog/modal-dialog.js";
import { OverlayPanel } from "./components/overlay-panel/overlay-panel.js";
import { TableRow } from "./components/table-row/table-row.js";
import { TableSummary } from "./components/table-summary/table-summary.js";
import { ConnectorPicker } from "./components/connector-picker/connector-picker.js";

export const DEFINE_MAP: any = {
    TextInput,
    ModalDialog,
    OverlayPanel,
    TableRow,
    TableSummary,
    ConnectorPicker,
    CalculatorApp
}

// Clear body HTML and attach main application component
document.body.innerHTML = "<calculator-app></calculator-app>";