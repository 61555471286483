import "./module-picker.scss";
import template from "./module-picker.hbs";
import { component, query } from "../../../hiyo/decorators.js";
import { ModalDialog } from "../modal-dialog/modal-dialog.js";
import { ModulePickerOptions } from "./types.js";
import { TextInput } from "../text-input/text-input.js";

@component(template)
export class ModulePicker extends ModalDialog<ModulePickerOptions> {

    // Properties
    public modules: any[];

    // Components
    @query("text-input[name=search]")public input: TextInput;

    // Event handling methods
    public onSelect(module: any): void {};

    public onAttach() {
        // Filter non-selected modules from pricelist
        this.modules = this.options.pricelist.modules.filter((x: any) => !this.options.calculation.modules.some(y => y.article.code == x.code));

        // Sort
        this.modules.sort((a, b) => a.name.localeCompare(b.name));
    }

    public onRender(): void {
        // Focus input
        //this.input.focus();

        // Search on value
        this.input.onKey = () => {
            this.search(this.input.options.value);
        }
    }

    public selectModule(i: number) {
        // Get selected module
        let module = this.modules[i];

        // Close self
        this.close();

        // OnSelect handler
        this.onSelect(module);
    }

    public search(term: string): void {
        // Show or hide matching items
        this.querySelectorAll("section div.item").forEach((e: HTMLElement) => {
            e.style.display = (term == null || e.textContent.toLowerCase().includes(term.toLowerCase())) ? "flex" : "none";
        });
    }

}