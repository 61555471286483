import { Log } from "./log.js";
import { ObjectHelper } from "./object-helper.js";
import { StringHelper } from "./string-helper.js";

export const STORAGE_LANG_ID = "hiyo.lang";
export const DEFAULT_LANG = "en";

export class Messages {

    private static lang: string;
    public static store: { [lang: string]: any } = {};

    public static setLang(lang: string): void {
        // Set lang
        Messages.lang = lang;

        // Store to local storage
        localStorage.setItem(STORAGE_LANG_ID, lang);
    }

    public static guessLang(): void {
        const storedLang = localStorage.getItem(STORAGE_LANG_ID);
        const browserLanguage = navigator.language;

        // Take lang from localStorage
        if (storedLang) {
            Messages.setLang(storedLang);
            Log.i(`Messages: Language set to "${storedLang}" (from localStorage)`);
        }
        // Take lang from browser navigator settings
        else if (browserLanguage && browserLanguage.length >= 2) {
            Messages.setLang(browserLanguage.substr(0, 2));
            Log.i(`Messages: Language set to "${this.lang}" (from navigator)`);
        }
        // No strategy: use default
        else {
            Messages.setLang(DEFAULT_LANG);
            Log.w(`Messages: Could not get language neither from localStorage nor navigator. Will use "${DEFAULT_LANG}" as default`);
        }
    }

    public static get(key: string, ...args: any): any {
        if (!Messages.lang) {
            Log.w(`Messages: Language not set. Use setLand() or guessLang() first.`);
            return null;
        }

        // Language store
        let store = Messages.store[Messages.lang];
        let defaultStore =  Messages.store[DEFAULT_LANG];

        if (!store) {
            Log.w(`Messages: Messages for language "${Messages.lang}" not added. Use add() to register translation messages.`);
            return null;
        }

        // Get messages object from store
        let message = ObjectHelper.getProperty(store, key) || ObjectHelper.getProperty(defaultStore, key);

        // Message not exist?
        if (message == null) {
            // Fallback to english
            Log.w(`Messages: Message "${key}" not found (fallback failed)`)
        }

        // Formatted string support (printf)
        // If no message, we will use key string instead
        return StringHelper.format(message ?? key, ...args);
    }

    public static add(lang: string, messages: any): void {
        Messages.store[lang] = messages;
    }

}