import { StringHelper } from "./string-helper.js";
import { Messages } from "./messages.js";
import { NumberHelper } from "./number-helper.js";
import Handlebars from "handlebars";

export class Templates {

    public static registerHelpers(): void {
        // kebab-case
        Handlebars.registerHelper("kebabcase", (s: string) => {
            return StringHelper.toKebabCase(s);
        });

        // Equals
        Handlebars.registerHelper("eq", function(x: any, y: any, options: any) {
            if (x == y) {
                return options.fn(this);
            }
            return options.inverse(this);
        });

        // Not equal
        Handlebars.registerHelper("ne", function(x: any, y: any, options: any) {
            if (x != y) {
                return options.fn(this);
            }
            return options.inverse(this);
        });

        // Less than
        Handlebars.registerHelper("lt", function(x: any, y: any, options: any) {
            if (x < y) {
                return options.fn(this);
            }
            return options.inverse(this);
        });

        // Greater than
        Handlebars.registerHelper("gt", function(x: any, y: any, options: any) {
            if (x > y) {
                return options.fn(this);
            }
            return options.inverse(this);
        });

        // Localization
        Handlebars.registerHelper("message", (key: string | any, ...args: any) => {
            // Empty message?
            if (!key) {
                return;
            }

            // Not string key means that key must be evaluated from tag's body
            if (typeof key != "string") {
                key = key.fn(this);
            }
            else {
                // Remove last arguments, hHandlebars context
                args.pop();
            }

            return Messages.get(key, ...args);
        });

        // asNumber
        Handlebars.registerHelper("asNumber", function(t: string, d: number, options: any) {
            return NumberHelper.toNumber(t, options ? d : 2);
        });
    }

}
