// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/assets/images/icons/v2/20/20-minus.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("/assets/images/icons/v2/20/20-plus.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("/assets/images/icons/v2/20/20-minus-3.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table-row {
  display: flex;
  flex-direction: row;
  transition: all 150ms cubic-bezier(0, 0, 0.38, 0.9) 0ms;
  transition-property: opacity, height, min-height;
  min-height: 56px;
  background-color: #F2F2F2;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  overflow: hidden;
  padding: 0 20px;
  margin-bottom: 8px;
  border-radius: 6px;
}
table-row div.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 8px 0;
}
table-row div.column div.label {
  font-family: "Incinet Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  padding: 8px 0;
  color: #181818;
}
table-row div.column div.items {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 0;
}
table-row div.column div.items p {
  font-family: "Incinet Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  color: #181818;
}
table-row div.column div.items p.name {
  min-width: 60px;
}
table-row div.column div.items div.input {
  display: flex;
  flex-direction: row;
  width: 160px;
  box-sizing: border-box;
  background-color: #FFFFFF;
  padding: 8px;
  margin: 0 8px;
  border-radius: 6px;
  align-items: center;
}
table-row div.column div.items div.input div.icon {
  cursor: pointer !important;
  user-select: none;
  width: 20px;
  height: 20px;
  margin: 0 8px;
  background-color: #181818;
}
table-row div.column div.items div.input div.icon-minus {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
table-row div.column div.items div.input div.icon-plus {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
table-row div.column div.items div.input input {
  flex-grow: 1;
  font-family: "Incinet Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  width: 72px;
  outline: 0;
  text-align: center;
}
table-row div.column div.items div.input input::-webkit-outer-spin-button, table-row div.column div.items div.input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
table-row div.column-left {
  text-align: left;
}
table-row div.column-right {
  flex-grow: 1;
  text-align: right;
}
table-row div.icon {
  cursor: pointer !important;
  user-select: none;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  background-color: #FF1267;
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}
table-row[size=Large] {
  min-height: 90px;
}`, "",{"version":3,"sources":["webpack://./src/calculator/components/table-row/table-row.scss","webpack://./src/runko/styles/_mixins.scss","webpack://./src/runko/styles/_colors.scss","webpack://./src/runko/styles/_styles.scss"],"names":[],"mappings":"AAIA;EC+BE,aAAA;EACA,mBAAA;EA+CA,uDAAA;EACA,gDAAA;ED7EA,gBAAA;EACA,yBEHQ;EFIR,mBAAA;EACA,8BAAA;EACA,SAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;EACA,kBAAA;AADF;AAGE;ECuBA,aAAA;EACA,sBAAA;EDtBE,uBAAA;EACA,aAAA;AAAJ;AAEI;ECrBF,0CAAA;EEWA,eAAA;EACA,kBAAA;EACA,mBAAA;EHUI,cAAA;EACA,cEtBI;AFyBV;AAAI;ECOF,aAAA;EACA,mBAAA;EDNI,mBAAA;EACA,cAAA;AAGN;AADM;EChCJ,0CAAA;EEIA,eAAA;EACA,kBAAA;EACA,mBAAA;EH4BM,cEhCE;AFsCV;AAJQ;EACE,eAAA;AAMV;AADM;ECRJ,aAAA;EACA,mBAAA;EDSM,YAAA;EACA,sBAAA;EACA,yBE7CG;EF8CH,YAAA;EACA,aAAA;EACA,kBAAA;EACA,mBAAA;AAIR;AAFQ;ECKN,0BAAA;EACA,iBAAA;EDJQ,WAAA;EACA,YAAA;EACA,aAAA;EACA,yBEvDA;AF4DV;AAHU;EACE,mDAAA;AAKZ;AAFU;EACE,mDAAA;AAIZ;AAAQ;ECpBN,YAAA;EAhDA,0CAAA;EEWA,eAAA;EACA,kBAAA;EACA,mBAAA;EH0DQ,WAAA;EACA,UAAA;EACA,kBAAA;AAKV;AAHU;EAEE,wBAAA;EACA,SAAA;AAIZ;AAEI;EACE,gBAAA;AAAN;AAGI;ECxCF,YAAA;ED0CI,iBAAA;AADN;AAKE;ECrCA,0BAAA;EACA,iBAAA;EA/BA,WDqE6B;ECpE7B,YDoEmC;ECnEnC,eDmE6B;EClE7B,gBDkEmC;EACjC,yBE5FS;EF6FT,mDAAA;AACJ;AAEE;EACE,gBAAA;AAAJ","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
