import "./table-row.scss";
import template from "./table-row.hbs";
import { component } from "../../../hiyo/decorators.js";
import { Component } from "../../../hiyo/component.js";
import { Context } from "../../../hiyo/context.js";
import { TableRowOptions } from "./types.js";
import { MathHelper } from "../../../hiyo/math-helper.js";
import { SystemHelper } from "../../../hiyo/system-helper.js";

const DATASOURCE_COUNT_MIN = 0;
const DATASOURCE_COUNT_MAX = 100000;
const ARTICLE_COUNT_MIN = 1;
const ARTICLE_COUNT_MAX = 240;

@component(template)
export class TableRow extends Component<Context, TableRowOptions> {

    // Event handling methods
    public onChange(): void {} // Row data was changed

    public render(): void {
        // Recalculate article price
        this.options.article.total = this.options.article.price * this.options.article.count;
        this.options.total = this.options.article.total;

        // Recalculate datasource price
        if (this.options.datasources) {
            for (let datasource of this.options.datasources) {
                datasource.total = datasource.price * datasource.count;
                this.options.total += datasource.total;
            }
        }

        // Super call
        super.render();
    }

    public updateArticleCount(delta: number): void {
        // Increase/decrease count
        this.options.article.count += delta;

        // Validation
        this.options.article.count = MathHelper.clamp(this.options.article.count, ARTICLE_COUNT_MIN, ARTICLE_COUNT_MAX);

        // Redraw
        this.render();

        // OnChangeHandler
        this.onChange();
    }

    public setArticleCount(count: number): void {
        // Set new count
        this.options.article.count = Number(count);

        // Validation
        this.options.article.count = MathHelper.clamp(this.options.article.count, ARTICLE_COUNT_MIN, ARTICLE_COUNT_MAX);

        // Redraw
        this.render();

        // OnChangeHandler
        this.onChange();
    }

    public setArticlePrice(price: number): void {
        // Set new price
        this.options.article.price = Number(price);

        // Redraw
        this.render();
    }

    public updateDatasourceCount(i: number, delta: number): void {
        // Increase/decrease count
        this.options.datasources[i].count += delta;

        // Validation
        this.options.datasources[i].count = MathHelper.clamp(this.options.datasources[i].count, DATASOURCE_COUNT_MIN, DATASOURCE_COUNT_MAX);

        // Redraw
        this.render();

        // OnChangeHandler
        this.onChange();
    }

    public setDatasourceCount(i: number, count: number): void {
        // Reset count?
        this.options.datasources[i].count = Number(count);

        // Validation
        this.options.datasources[i].count = MathHelper.clamp(this.options.datasources[i].count, DATASOURCE_COUNT_MIN, DATASOURCE_COUNT_MAX);

        // Redraw
        this.render();

        // OnChangeHandler
        this.onChange();
    }

    public async removeSelf(): Promise<void> {
        // Transition final state
        this.style.opacity = "0";
        //this.style.height = "0";
        //this.style.minHeight = "0";

        // Wait till animation ends
        await SystemHelper.sleep(150);

        // Remove node
        this.remove();

        // OnChangeHandler
        this.onChange();
    }

}