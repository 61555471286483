// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/assets/images/icons/v2/24/24-send.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `calculator-app {
  width: 100%;
  height: 100%;
  overflow: auto;
}
calculator-app > header {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  background-color: #122AFF;
  align-items: center;
  justify-content: center;
  padding: 28px 24px;
}
calculator-app > header div.title {
  display: flex;
  flex-direction: row;
  width: 820px;
  align-items: center;
  justify-content: space-between;
}
calculator-app > header div.title div.label {
  font-family: "Incinet Semi Bold", sans-serif;
  font-size: 18px;
  font-style: normal;
  line-height: normal;
  color: #FFFFFF;
}
calculator-app > main {
  display: block;
  background-color: #F2F2F2;
}
calculator-app > main div.content {
  max-width: 820px;
  margin: 0 auto;
  padding: 56px 16px 64px 16px;
}
calculator-app > main div.content h1 {
  font-family: "Incinet Semi Bold", sans-serif;
  font-size: 21px;
  font-style: normal;
  line-height: normal;
  color: #181818;
  margin-bottom: 32px;
}
calculator-app > main div.content section {
  padding: 24px 24px 24px 24px;
  background-color: #FFFFFF;
  border-radius: 14px;
  margin-bottom: 32px;
}
calculator-app > main div.content section div.title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}
calculator-app > main div.content section div.title h2 {
  font-family: "Incinet Semi Bold", sans-serif;
  font-size: 21px;
  font-style: normal;
  line-height: normal;
  color: #181818;
}
calculator-app > main div.content section div.title h3 {
  font-family: "Incinet Semi Bold", sans-serif;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  color: #181818;
}
calculator-app > main div.content section div.title div.action {
  font-family: "Incinet Semi Bold", sans-serif;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  cursor: pointer !important;
  user-select: none;
  color: #122AFF;
}
calculator-app > main div.content section div.title-solo {
  margin-bottom: 16px;
}
calculator-app > main div.content section p.description {
  font-family: "Incinet Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  color: #181818;
  line-height: 140%;
  margin-bottom: 8px;
}
calculator-app > main div.content section p.description-solo {
  margin-bottom: 32px;
}
calculator-app > main div.content section div.fieldset {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
calculator-app > main div.content section div.table {
  margin: 16px 0 40px 0;
}
calculator-app > main div.content section.form {
  background-color: unset;
  border: 2px solid #FFFFFF;
  padding-bottom: 0;
}
calculator-app > main div.content p.disclaimer {
  font-family: "Incinet Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  padding: 0 24px 40px 24px;
}
calculator-app > main div.content p.disclaimer strong {
  font-family: "Incinet Semi Bold", sans-serif;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
}
calculator-app > main div.content div.toolbar {
  display: flex;
  flex-direction: row;
  cursor: pointer !important;
  user-select: none;
  justify-content: flex-end;
  margin-bottom: 64px;
}
calculator-app > main div.content div.toolbar div.button {
  display: flex;
  flex-direction: row;
  height: 56px;
  background-color: #122AFF;
  align-items: center;
  gap: 10px;
  padding: 0 12px 0 16px;
  border: 2px solid #122AFF;
  border-radius: 6px;
}
calculator-app > main div.content div.toolbar div.button div.label {
  font-family: "Incinet Semi Bold", sans-serif;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  color: #FFFFFF;
}
calculator-app > main div.content div.toolbar div.button div.icon {
  width: 24px;
  height: 24px;
  background-color: #FFFFFF;
}
calculator-app > main div.content div.toolbar div.button div.icon-submit {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
@media only screen and (max-width: 858px) {
  calculator-app text-input {
    width: 100% !important;
  }
}
@media print {
  calculator-app {
    height: unset;
    overflow: unset;
  }
}`, "",{"version":3,"sources":["webpack://./src/calculator/components/calculator-app/calculator-app.scss","webpack://./src/runko/styles/_mixins.scss","webpack://./src/runko/styles/_colors.scss","webpack://./src/runko/styles/_styles.scss"],"names":[],"mappings":"AAIA;EACE,WAAA;EACA,YAAA;EACA,cAAA;AAHF;AAKE;EC0BA,aAAA;EACA,mBAAA;EDzBE,sBAAA;EACA,yBEXQ;EFYR,mBAAA;EACA,uBAAA;EACA,kBAAA;AAFJ;AAII;ECkBF,aAAA;EACA,mBAAA;EDjBI,YAAA;EACA,mBAAA;EACA,8BAAA;AADN;AAMM;ECrBJ,4CAAA;EEwDA,eAAA;EACA,kBAAA;EACA,mBAAA;EHnCM,cE1BG;AFyBX;AAME;EACE,cAAA;EACA,yBE9BM;AF0BV;AAMI;EACE,gBAAA;EACA,cAAA;EACA,4BAAA;AAJN;AAMM;ECrCJ,4CAAA;EE+DA,eAAA;EACA,kBAAA;EACA,mBAAA;EH1BM,cEzCE;EF0CF,mBAAA;AADR;AAIM;EACE,4BAAA;EACA,yBEhDG;EFiDH,mBAAA;EACA,mBAAA;AAFR;AAIQ;ECnBN,aAAA;EACA,mBAAA;EDoBQ,8BAAA;EACA,mBAAA;EACA,kBAAA;AADV;AAGU;ECvDR,4CAAA;EE+DA,eAAA;EACA,kBAAA;EACA,mBAAA;EHRU,cE3DF;AF6DV;AACU;EC5DR,4CAAA;EEiDA,eAAA;EACA,kBAAA;EACA,mBAAA;EHWU,cEhEF;AFoEV;AADU;ECjER,4CAAA;EEiDA,eAAA;EACA,kBAAA;EACA,mBAAA;EFEA,0BAAA;EACA,iBAAA;EDcU,cExEA;AF+EZ;AAJU;EACE,mBAAA;AAMZ;AAFQ;EChFN,0CAAA;EEWA,eAAA;EACA,kBAAA;EACA,mBAAA;EHqEQ,cEhFA;EFiFA,iBAAA;EACA,kBAAA;AAOV;AALU;EACE,mBAAA;AAOZ;AAHQ;ECzDN,aAAA;EACA,mBAAA;ED0DQ,eAAA;EACA,8BAAA;AAMV;AAHQ;EACE,qBAAA;AAKV;AAFQ;EACE,uBAAA;EACA,yBAAA;EACA,iBAAA;AAIV;AACM;EC7GJ,0CAAA;EEWA,eAAA;EACA,kBAAA;EACA,mBAAA;EHkGM,yBAAA;AAIR;AAFQ;EC7GN,4CAAA;EEiDA,eAAA;EACA,kBAAA;EACA,mBAAA;AHkEF;AAHM;ECpFJ,aAAA;EACA,mBAAA;EAsBA,0BAAA;EACA,iBAAA;ED+DM,yBAAA;EACA,mBAAA;AAOR;AALQ;EC1FN,aAAA;EACA,mBAAA;ED2FQ,YAAA;EACA,yBE/HE;EFgIF,mBAAA;EACA,SAAA;EACA,sBAAA;EACA,yBAAA;EACA,kBAAA;AAQV;AANU;EClIR,4CAAA;EEiDA,eAAA;EACA,kBAAA;EACA,mBAAA;EHiFU,cEvID;AFkJX;AARU;EACE,WAAA;EACA,YAAA;EACA,yBE7ID;AFuJX;AARY;EACE,mDAAA;AAUd;AAFE;EACE;IACE,sBAAA;EAIJ;AACF;AADE;EA5JF;IA6JI,aAAA;IACA,eAAA;EAIF;AACF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
