import "./modal-dialog.scss";
import template from "./modal-dialog.hbs";
import { component, events } from "../../../hiyo/decorators.js";
import { ModalDialogOptions } from "./types.js";
import { Component } from "../../../hiyo/component.js";
import { Context } from "../../../hiyo/context.js";
import { OverlayPanel } from "../overlay-panel/overlay-panel.js";

@component(template)
@events("keyup")
export class ModalDialog<T extends ModalDialogOptions = ModalDialogOptions> extends Component<Context, T> {

    public onCreate(): void {
        // Cancel event bubbling
        this.addEventListener("click", (e: Event) => {
            e.stopPropagation();
        })
    }

    public onEvent(event: Event) {
        // Close self on ESC
        if (event.type == "keyup" && (<KeyboardEvent>event).code == "Escape") {
            this.close();
        }
    }

    public close(): void {
        // Close via overlay if displayed
        if (this.parentComponent instanceof OverlayPanel) {
            // Close self via overlay
            this.parentComponent.remove();
        }
        // Else remove self directly from DOM
        else {
            this.remove();
        }
    }
}