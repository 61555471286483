export const LOCALE = localStorage.getItem("hiyo.langId") || navigator.language;

export class NumberHelper {

    public static toNumber(t: any, maxFractionDigits: number = 0, minFractionDigits: number = 0): string {
        if (!t && t != 0) {
            return null;
        }
        return Number(t).toLocaleString(LOCALE, {
            minimumFractionDigits: minFractionDigits,
            maximumFractionDigits: maxFractionDigits
        });
    }

}
