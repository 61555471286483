// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `overlay-panel {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(24, 24, 24, 0.1);
}`, "",{"version":3,"sources":["webpack://./src/calculator/components/overlay-panel/overlay-panel.scss"],"names":[],"mappings":"AAIA;EACE,kBAAA;EACA,OAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,uCAAA;AAHF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
