// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `text-input {
  position: relative;
  display: block;
  box-sizing: border-box;
  padding: 8px 12px;
  background-color: #FFFFFF;
  margin-bottom: 32px;
  border: 2px solid #F2F2F2;
  border-radius: 6px;
}
text-input label {
  font-family: "Incinet Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  color: #181818;
  margin-bottom: 8px;
}
text-input input,
text-input textarea {
  font-family: "Incinet Semi Bold", sans-serif;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  width: 100%;
  color: #181818;
  background-color: transparent;
  outline: 0;
  resize: none;
  padding: 0 0 4px 0;
  border: 0;
}
text-input input:-webkit-autofill, text-input input:-webkit-autofill:hover, text-input input:-webkit-autofill:focus, text-input input:-webkit-autofill:active,
text-input textarea:-webkit-autofill,
text-input textarea:-webkit-autofill:hover,
text-input textarea:-webkit-autofill:focus,
text-input textarea:-webkit-autofill:active {
  -webkit-text-fill-color: #181818;
  -webkit-box-shadow: 0 0 0 1000px #FFFFFF inset;
}
text-input div.reason {
  font-family: "Incinet Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  position: absolute;
  left: 0;
  bottom: -24px;
  color: #FF1267;
}
text-input[invalid=true] {
  border-color: #FF1267;
}`, "",{"version":3,"sources":["webpack://./src/calculator/components/text-input/text-input.scss","webpack://./src/runko/styles/_colors.scss","webpack://./src/runko/styles/_mixins.scss","webpack://./src/runko/styles/_styles.scss"],"names":[],"mappings":"AAIA;EACE,kBAAA;EACA,cAAA;EACA,sBAAA;EACA,iBAAA;EACA,yBCPS;EDQT,mBAAA;EACA,yBAAA;EACA,kBAAA;AAHF;AAKE;EEbA,0CAAA;ECIA,eAAA;EACA,kBAAA;EACA,mBAAA;EHSE,cCbM;EDcN,kBAAA;AAAJ;AAGE;;EEfA,4CAAA;ECiDA,eAAA;EACA,kBAAA;EACA,mBAAA;EHjCE,WAAA;EACA,cCrBM;EDsBN,6BAAA;EACA,UAAA;EACA,YAAA;EACA,kBAAA;EACA,SAAA;AAEJ;AAAI;;;;;EAIE,gCChCI;EDiCJ,8CAAA;AAGN;AACE;EEvCA,0CAAA;ECIA,eAAA;EACA,kBAAA;EACA,mBAAA;EHmCE,kBAAA;EACA,OAAA;EACA,aAAA;EACA,cCvCS;AD2Cb;AADE;EACE,qBC3CS;AD8Cb","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
