import "./overlay-panel.scss";
import template from "./overlay-panel.hbs";
import { component } from "../../../hiyo/decorators.js";
import { Component } from "../../../hiyo/component.js";

@component(template)
export class OverlayPanel extends Component {

    public onCreate() {
        // Remove self (with all child elements) on click)
        this.addEventListener("click", () => {
            this.remove();
        });
    }

}