import "./table-summary.scss";
import template from "./table-summary.hbs";
import { component } from "../../../hiyo/decorators.js";
import { Component } from "../../../hiyo/component.js";
import { Context } from "../../../hiyo/context.js";
import { TableSummaryOptions } from "./types.js";
import { CalculationTotal } from "../calculator-app/types.js";

@component(template)
export class TableSummary extends Component<Context, TableSummaryOptions> {

    onCreate() {
        // Zero values as default
        this.options = {
            total: {
                licenses: 0,
                services: 0,
                price: 0
            }
        }
    }

    public setTotal(total: CalculationTotal): void {
        // Sum up all calculation costs
        this.options.total = total;

        // Redraw
        this.render();
    }

}