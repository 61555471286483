export class DomHelper {

    public static appendBodyChild(child: Element, nested?: Element): void {
        // Nesting?
        if (nested) {
            child.appendChild(nested);
        }

        // Add to DOM
        document.body.appendChild(child);
    }

}
