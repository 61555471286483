import "./text-input.scss";
import template from "./text-input.hbs";
import { TextInputOptions } from "./types.js";
import { component } from "../../../hiyo/decorators.js";
import { Component } from "../../../hiyo/component.js";
import { FormatHelper } from "../../../hiyo/format-helper.js";

@component(template)
export class TextInput extends Component<TextInputOptions> {

    // Event handling methods
    public onKey(key: number): void {}

    public change() {
        // Validate self
        this.validate();
    }

    public key(key: number) {
        // Empty string fix
        if (this.options.value == "") {
            this.options.value = null;
        }

        // Read value
        this.options.value = this.querySelector<HTMLInputElement>((this.options.format == "Text") ? "textarea" : "input").value;

        // ESC
        if (key == 27) {
            // Stop propagation to prevent closing details etc.
            event.stopPropagation();

            // Sets value to options
            this.options.value = null;

            // Redraw
            this.render();

            // Focus again
            this.focus();
        }

        // OnKey handler
        this.onKey(key);
    }

    public validate(): boolean {
        let reason = null;

        // Required and null?
        if (this.options.required && (this.options.value == null || this.options.value.length == 0)) {
            reason = "Could not be empty";
        }

        // Invalid email?
        if (this.options.format == "Email" && this.options.value?.length && !FormatHelper.isEmail(this.options.value)) {
            reason = "Not valid email address";
        }

        // Invalid phone?
        if (this.options.format == "Phone" && this.options.value?.length && !FormatHelper.isPhone(this.options.value)) {
            reason = "Not valid phone number";
        }

        // Reason has changed?
        if (this.options.reason != reason) {
            // Sets invalid and reason options
            this.options.invalid = (reason != null);
            this.options.reason = reason;

            // Redraw
            this.render();
        }

        // Returns true if input passed the validation
        return (this.options.reason == null);
    }

    public focus() {
        // Focus input too
        this.querySelector<HTMLElement>((this.options.format == "Text") ? "textarea" : "input").focus();

        // Super focus
        super.focus();
    }
}