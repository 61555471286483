import { PropertyResolver } from "./types.js";
import { Http } from "./http.js";
import { Log } from "./log.js";

export class ObjectHelper {

    public static getProperty(data: any, name: string | PropertyResolver): any {
        if (name == null) {
            return null;
        }
        else if (typeof name == "function") {
            return name(data);
        }
        else {
            let keys = name.split(".");

            // Search nested properties
            for (let key of keys) {
                data = data[key];

                // Null means data has no property with key name
                if (data == null) {
                    return null
                }
            }

            return data;
        }
    }

    public static cleanProperties(data: any): any {
        // Result object
        const result: any = {};

        // Go through all values
        Object.keys(data).forEach(key => {
            // If value is object and not array then do recursion
            if (data[key] && !data[key]?.length && typeof data[key] == "object") {
                result[key] = ObjectHelper.cleanProperties(data[key]);
            }
            // Assign flat value
            else if (data[key] != null) {
                result[key] = data[key];
            }
        });

        return result;
    }

    public static async loadJson<T>(path: string): Promise<T> {
        let http = new Http();

        Log.d(`Loading JSON from ${path}`);

        // Load JSON via HTTP
        return await http.request("GET", path);
    }
}