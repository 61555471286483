// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table-summary {
  display: flex;
  flex-direction: column;
  border: 2px solid #F2F2F2;
  border-radius: 6px;
}
table-summary div.row {
  display: flex;
  flex-direction: row;
  min-height: 56px;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 0 20px;
  border-bottom: 2px solid #F2F2F2;
}
table-summary div.row:last-child {
  border-bottom: none;
}
table-summary div.row div.name {
  font-family: "Incinet Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  color: #181818;
}
table-summary div.row div.price {
  font-family: "Incinet Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  color: #181818;
  text-align: right;
}
table-summary div.row-total {
  background-color: #F2F2F2;
}
table-summary div.row-total div.name {
  font-family: "Incinet Semi Bold", sans-serif;
  font-size: 18px;
  font-style: normal;
  line-height: normal;
}
table-summary div.row-total div.price {
  font-family: "Incinet Semi Bold", sans-serif;
  font-size: 18px;
  font-style: normal;
  line-height: normal;
}`, "",{"version":3,"sources":["webpack://./src/calculator/components/table-summary/table-summary.scss","webpack://./src/runko/styles/_mixins.scss","webpack://./src/runko/styles/_styles.scss","webpack://./src/runko/styles/_colors.scss"],"names":[],"mappings":"AAIA;ECoCE,aAAA;EACA,sBAAA;EDnCA,yBAAA;EACA,kBAAA;AAFF;AAIE;EC0BA,aAAA;EACA,mBAAA;EDzBE,gBAAA;EACA,8BAAA;EACA,mBAAA;EACA,SAAA;EACA,eAAA;EACA,gCAAA;AADJ;AAGI;EACE,mBAAA;AADN;AAII;ECrBF,0CAAA;ECWA,eAAA;EACA,kBAAA;EACA,mBAAA;EFUI,cGrBI;AHsBV;AAEI;EC1BF,0CAAA;ECWA,eAAA;EACA,kBAAA;EACA,mBAAA;EFeI,cG1BI;EH2BJ,iBAAA;AAGN;AAAI;EACE,yBG7BI;AH+BV;AAAM;EC/BJ,4CAAA;ECwDA,eAAA;EACA,kBAAA;EACA,mBAAA;AFrBF;AAFM;ECnCJ,4CAAA;ECwDA,eAAA;EACA,kBAAA;EACA,mBAAA;AFfF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
